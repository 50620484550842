<template>
  <div class="eyeScene">
    <h3 style="padding-top: 0.2rem;">您的主要用眼场景有哪些？</h3>
    <div class="nr-box">
      <el-checkbox-group v-model="checkList">
        <div class="nr-content">
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj1.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>驾驶场景</p>
            </div>
            <el-checkbox class="fxk" label="0"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj2.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>精准视觉</p>
            </div>
            <el-checkbox class="fxk" label="1"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj3.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>电子数码</p>
            </div>
            <el-checkbox class="fxk" label="2"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj4.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>上课会议</p>
            </div>
            <el-checkbox class="fxk" label="3"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj5.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>运动锻炼</p>
            </div>
            <el-checkbox class="fxk" label="4"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj6.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>术后和训练</p>
            </div>
            <el-checkbox class="fxk" label="5"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj7.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>日常生活</p>
            </div>
            <el-checkbox class="fxk" label="6"></el-checkbox>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj8.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>旅行与户外</p>
            </div>
            <el-checkbox class="fxk" label="7"></el-checkbox>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.InquiryRemark2">
<!--        InquriyRemark2-->
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
name: "eyeScene",
  data() {
    return {
      checkList:[],

      srcList: [
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj1.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj2.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj3.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj4.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj5.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj6.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj7.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/cryycj8.jpg",
      ],
      postData: {
        InquiryRemark2: '',
        InquiryAcitivity: ''
      },
      textarea2: ''
    }
  },
  watch: {
    checkList: {
      handler(newName, oldName) {
        if (newName.length > 3) {
          this.checkList = [newName[0], newName[1], newName[2]]
          return this.$alert('只能选择三项场景', "提示")
        }
        let arr = [false,false,false,false,false,false,false,false]
        this.checkList.forEach(item => {
          arr[item] = true
        })
        this.postData.InquiryAcitivity = arr
        // let items = JSON.parse(JSON.stringify(this.postData))
        // this.$store.commit('physicianVisits/upDatas', items)
      },
      immediate: true,
      deep: true
    },
    postData: {
      handler(n, o) {
        this.$store.commit('physicianVisits/UpAldult', {
          type: 3,
          obj: n
        })
      },
      deep: true,
      immediate: true
    },
    "$store.state.physicianVisits.isgetData": function (n, o) {
      for (let key in this.postData) {
        this.postData[key] = this.$store.state.physicianVisits.datas[key]
      }
      let arr = this.postData.InquiryAcitivity,newarr = []
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i]) {
            newarr.push(i.toString())
          }
        }
      }
      this.checkList = newarr
    }
/*    '$store.state.physicianVisits.xzConsulting': function (n, o) {
      if (n) {
        this.postData = this.$store.state.physicianVisits.datas
        let arr = this.$store.state.physicianVisits.datas.InquiryAcitivity,newarr = []
        if (arr && arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i]) {
              newarr.push(i.toString())
            }
          }
        }
        this.checkList = newarr
      }
    },
    '$store.state.users.CsUser': function (n, o) {
      if (!n) {
        this.checkList = []
        this.postData = {}
      }
    }*/
  },
  created() {

  },
  methods: {
    drdata(data) {
      if (data) {
        this.postData.S_OP_Json.S_OP_R_SC_DSOD = data.RightSPH?(data.RightSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_DCOD = data.RightCYL?(data.RightCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_XOD = data.RightAxis?(data.RightAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD = (data.PD/2)?(data.PD/2).toString():''

        this.postData.S_OP_Json.S_OP_R_SC_DSOS = data.LeftSPH?(data.LeftSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_DCOS = data.LeftSPH?(data.LeftCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_XOS = data.LeftAxis?(data.LeftAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS = (data.PD/2)?(data.PD/2).toString():''

        this.showdrsb = false
      }
    }
  },
  components: {
    ImportEquipment: () => import('@/components/specialSurveys/refractive/importEquipment'),
  }
}
</script>

<style scoped lang="scss">
::v-deep .fxk .el-checkbox__inner::after {
  width: 8px;
  height: 16px;
  left: 10px;
  top: 2px;
}
::v-deep .fxk .el-checkbox__inner {
  width: 30px;
  height: 30px;
}
::v-deep .fxk .el-checkbox__label {display: none;}
.nr-box {
  img {
    width: 10vw;
    border-radius: 5px;
  }
}
.nr-content {
  display: flex;
  width: 85vw;
  flex-wrap: wrap;
  padding: 0 2vw;
  .zynr {
    padding: 4px;
    border: 2px solid #8f8e8e;
    border-radius: 5px;
    position: relative;
    margin-bottom: 8px;
    p {
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: 30%;
      background: rgba(0,0,0,0.5);
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nr-b-box {
    margin:0 10px;
    padding-top: 20px;
  }
}

.bz {
  width: 76vw;
  padding: 0 3vw;
  text-align: left;
  padding-top: 0.3rem;
}
</style>
